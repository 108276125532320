import { PrivilegeType } from '../types/User'
import { PrivilegeIdType } from '../generated/graphql'

export const getPrivilegeColour = (
  privilege: PrivilegeType | undefined,
): string => {
  if (privilege) {
    switch (privilege.key) {
      case PrivilegeIdType.None:
        return 'secondary'
      case PrivilegeIdType.Standard:
        return 'success'
      case PrivilegeIdType.Admin:
        return 'warning'
      case PrivilegeIdType.Super:
        return 'danger'
      default:
        return 'primary'
    }
  } else {
    return 'primary'
  }
}

export default getPrivilegeColour
