import React, { Suspense, useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, NavigateFunction } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

import './scss/style.scss'
import { AuthProvider, AuthContext } from './Auth'

// Adds messages only in a dev environment
if (process.env.NODE_ENV !== 'production') {
  console.log('Loading error messages')
  loadDevMessages()
  loadErrorMessages()
}

// Check if we're running in the browser.
/*
if (typeof window !== 'undefined') {
  // ✅ Only runs once per app load
  console.log('App RUN ONCE FUNCTION?')
}
*/

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const ForgotPassword = React.lazy(
  () => import('./views/pages/forgot/ForgotPassword'),
)
const ResetPassword = React.lazy(
  () => import('./views/pages/reset/ResetPassword'),
)
const ConfirmSignup = React.lazy(
  () => import('./views/pages/confirm/ConfirmSignup'),
)
const Subscribe = React.lazy(() => import('./views/pages/subscribe/Subscribe'))
const SubscribeConfirm = React.lazy(
  () => import('./views/pages/subscribe/SubscribeConfirm'),
)
const Unsubscribe = React.lazy(
  () => import('./views/pages/subscribe/Unsubscribe'),
)
function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/confirmsignup" element={<ConfirmSignup />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/subscribe/:userId" element={<SubscribeConfirm />} />
          <Route path="/unsubscribe/:userId" element={<Unsubscribe />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </AuthProvider>
  )
}

export default App
