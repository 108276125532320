import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import config from './config'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
const apiServer = config.api || config.apiPrivate

console.log('In index.tsx')
console.log('config:', config)
console.log(`API server: ${apiServer}`)

const httpLink = createHttpLink({
  uri: apiServer,
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? token : '',
    },
  }
})

const scoApiClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  headers: {
    'client-name': 'sco-app',
    Cookie: `token=${localStorage.getItem('token')}`,
  },
})

root.render(
  <StrictMode>
    <ApolloProvider client={scoApiClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
