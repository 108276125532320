import { InstrumentSectionType } from '../types/User'
import { SectionIdType } from '../generated/graphql'

const getSectionColour = (
  section: InstrumentSectionType | undefined,
): string => {
  if (section) {
    switch (section.key) {
      case SectionIdType.String:
        return 'secondary'
      case SectionIdType.Woodwind:
        return 'success'
      case SectionIdType.Brass:
        return 'warning'
      case SectionIdType.Percussion:
        return 'danger'
      default:
        return 'primary'
    }
  } else {
    return 'primary'
  }
}

export default getSectionColour
