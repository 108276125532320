import { CurrentUser } from '../Auth'
import { EditUserInput } from '../generated/graphql'

const editUserInputFromCurrentUser = (user: CurrentUser): EditUserInput => {
  console.log('editUserInputFromCurrentUser', user)
  const userInstruments: string[] = []
  user.loginUser?.userInstruments?.forEach((instrument) => {
    userInstruments.push(instrument.instrumentId)
  })

  const result: EditUserInput = {
    userId: user.loginUser?.userId || user.userId || 'ERROR',
    firstName: user.loginUser?.firstName,
    lastName: user.loginUser?.lastName,
    userInstruments: userInstruments,
  }

  console.log('returning', result)
  return result
}

export default editUserInputFromCurrentUser
