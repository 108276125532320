import { InstrumentSectionType, UserType } from '../types/User'
import { MultiSelectOption } from '../types'
import getSectionColour from './get-section-colour'
import { InstrumentsQuery } from '../generated/graphql'

const createInstrumentOptions = (
  instrumentsData: InstrumentsQuery | undefined,
  user: UserType,
) => {
  // Create structured data from the instruments returned from the server
  const instrumentsOptions: MultiSelectOption[] = []

  if (instrumentsData) {
    const editOrCreateUserInstruments: string[] = []

    // Create an array that contains the RoleIdType values for the editOrCreateUser
    user?.userInstruments?.forEach((instrument) => {
      editOrCreateUserInstruments.push(instrument.instrumentId)
    })

    instrumentsData.instruments?.forEach((instrument, index) => {
      if (instrument && instrument.name && instrument.key) {
        instrumentsOptions.push({
          text: instrument.name,
          value: instrument.instrumentId,
          selected: editOrCreateUserInstruments.includes(
            instrument.instrumentId,
          ),
          color: getSectionColour(
            instrument.instrumentSection as InstrumentSectionType,
          ),
        })
      }
    })
  }

  return instrumentsOptions
}

export default createInstrumentOptions
