type ConfigType = {
  stage: string
  region: string
  domain: string
  cookieStorageSamesite: string
  apiPrivate?: string
  apiPublic?: string
  api?: string
}

const productionConfig: ConfigType = {
  stage: 'production',
  region: 'us-east-1',
  domain: 'localhost',
  cookieStorageSamesite: 'false',
  apiPrivate: 'http://localhost:3000',
  api: 'https://api.scorchestra.uk',
}

export const developmentConfig: ConfigType = {
  stage: 'development',
  region: 'us-east-1',
  domain: 'localhost',
  cookieStorageSamesite: 'false',
  api: 'http://localhost:3000',
}

const stage = process.env.NODE_ENV || 'development'

let config: ConfigType

if (stage === 'production') config = productionConfig
else config = developmentConfig

export default config
