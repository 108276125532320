import React, { createContext, PropsWithChildren, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql/error'

import { CookiesProvider, useCookies } from 'react-cookie'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { createMongoAbility, AnyMongoAbility } from '@casl/ability'

import { LoginType, SignUpType, UserIdType, UserType } from './types/User'
import { getTimestampInSeconds } from './utils'
import {
  useLoginUserMutation,
  useSignUpMutation,
  useUserByIdLazyQuery,
  useForgotPasswordMutation,
  LoginUserMutation,
  SignUpMutation,
  SignUpInput,
  AppErrorCode,
} from './generated/graphql'

/*
 * Some jiggery pokery here due to user[key] being inferred as never. See
 * https://stackoverflow.com/questions/63687987/why-cant-some-of-the-properties-on-the-htmlscriptelement-be-assigned-to-in-type
 */
type Values<T extends object> = T[keyof T]
type UserTypeValue = Values<UserType>

interface ScoJwtPayload extends JwtPayload {
  userId: UserIdType
  userPrivilege: string
  userRoles: string[]
}

// Check if we're running in the browser.
/*
if (typeof window !== 'undefined') {
  // ✅ Only runs once per app load
  console.log('Auth RUN ONCE FUNCTION?')
}

 */

export const isValidEmailAddress = (s: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i.test(s)
}

export const isValidPassword = (s: string) => {
  const specialChars = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/
  return (
    specialChars.test(s) &&
    /[a-z]/.test(s) &&
    /[A-Z]/.test(s) &&
    /[0-9]/.test(s) &&
    s.length >= 8
  )
}

export const isValidCode = (s: string) => {
  return /^[0-9][0-9][0-9][0-9][0-9][0-9]$/.test(s)
}

type callbackType = (message: string, validated: boolean) => boolean | null

export const validateEmail = (email: string, cb: callbackType) => {
  if (!email) {
    return cb && cb('Please enter an email address', false)
  } else if (!isValidEmailAddress(email)) {
    return cb && cb('Please enter a valid email address', false)
  } else {
    return cb && cb('', true)
  }
}

export const validatePassword = (
  password: string,
  missingPasswordMessage: string | null,
  alertFormat: boolean,
  cb: callbackType,
) => {
  if (!password) {
    if (!missingPasswordMessage) {
      missingPasswordMessage = 'Please enter a password'
    }
    return cb && cb(missingPasswordMessage, false)
  } else if (alertFormat && !isValidPassword(password)) {
    return (
      cb &&
      cb(
        'Passwords must be at least 8 characters and contain at least one lowercase letter, uppercase letter, number and symbol characters',
        false,
      )
    )
  } else {
    return cb && cb('', true)
  }
}

export const validateCode = (code: string, cb: callbackType) => {
  if (!code) {
    return cb && cb('Please enter the code that was emailed to you', false)
  } else if (!isValidCode(code)) {
    return cb && cb('The code is a six digit number', false)
  } else {
    return cb && cb('', true)
  }
}

export type CurrentUser = {
  isAuthenticated: boolean
  loginUser?: UserType | null
  userId?: UserIdType
  isVerified?: boolean
  token?: string
  decodedToken?: ScoJwtPayload
  firstName?: string
  lastName?: string
  email?: string
  isSubscriber?: boolean
  avatar?: string
  userAbility?: AnyMongoAbility
  userPrivilege?: string
  userRoles?: string[]
}

const decodeToken = (currentUser: CurrentUser, token: string): boolean => {
  // This is to allow for slight variations in the clocks on client and server, measured in seconds
  const TOLERANCE = 5

  currentUser.token = token

  // Validate token
  try {
    const decodedToken = jwtDecode<ScoJwtPayload>(token)

    const timeNow = Math.floor(Date.now() / 1000)

    // Has it expired?
    if (
      decodedToken &&
      decodedToken.iat &&
      decodedToken.exp &&
      decodedToken.iat <= timeNow + TOLERANCE &&
      decodedToken.exp >= timeNow - TOLERANCE
    ) {
      console.log('token has not expired', decodedToken, 'timeNow', timeNow)
      currentUser.decodedToken = decodedToken
      currentUser.userId = decodedToken.userId
      return true
    }
    console.log('treating token as expired', decodedToken, 'timeNow', timeNow)
  } catch (e) {}

  console.log('decodeToken error')
  currentUser.decodedToken = undefined
  return false
}

const isAuthenticated = (user: CurrentUser): boolean => {
  const decodedToken = user.decodedToken

  return (
    decodedToken !== undefined &&
    !!decodedToken.userId &&
    typeof decodedToken.exp == 'number' &&
    new Date().valueOf() / 1000 < decodedToken?.exp
  )
}

export type GlobalContent = {
  register: (
    email: string,
    password: string,
    subscribe: boolean,
  ) => Promise<string>
  authenticate: (
    email: string,
    password: string,
    page: string | undefined,
  ) => Promise<string>
  forgotPassword: (email: string) => Promise<string>
  retrieveUser: () => Promise<CurrentUser>
  emailState: string
  setEmailState: React.Dispatch<React.SetStateAction<string>>
  passwordState: string
  setPasswordState: React.Dispatch<React.SetStateAction<string>>
  currentUser: CurrentUser
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser>>
  updateCurrentUserFromMutation: (
    user: UserType,
    token: string | undefined,
  ) => CurrentUser
  storeCurrentUserToken: (user: UserType) => void
  cookies: { token?: any }
  signOut: () => Promise<string>
}

export const AuthContext = createContext<GlobalContent>({
  register: (email: string, password: string, subscribe: boolean) =>
    Promise.resolve('/'),
  authenticate: (email, password, page) => Promise.resolve('/'),
  forgotPassword: (email) => Promise.resolve('/'),
  retrieveUser: () => Promise.resolve({} as CurrentUser),
  emailState: '',
  setEmailState: () => null,
  passwordState: '',
  setPasswordState: () => null,
  currentUser: {} as CurrentUser,
  setCurrentUser: () => null,
  updateCurrentUserFromMutation: () => {
    return {} as CurrentUser
  },
  storeCurrentUserToken: () => null,
  cookies: {},
  signOut: async () => '',
})

type ErrorCode = {
  code: string
  message: string
}

export const friendlyErrorMessage = (
  err: Error | ErrorCode | null | undefined,
): string => {
  if (err) {
    // The error is sometimes an Error object and other times contains just {code: 'value'}.
    // There's no code property on an Error object
    const code =
      ('code' in err ? err.code : null) || ('name' in err ? err.name : null)
    switch (code) {
      case null:
      case undefined:
        return 'Undefined error, please report.'
      case 'UsernameExistsException':
        return 'An account with the given email already exists. <a href="/login">Login instead?</a>'
      case 'CodeDeliveryFailureException':
        return 'A system authentication error has occurred, sorry! Please report this.'
      case 'NotAuthorizedException':
        return 'Incorrect email or password. Please try again. Check that the CAPS LOCK key is not turned on.'
      case 'NetworkError':
        return 'There was a problem with your network. Please try again.'
      case 'ExpiredCodeException':
        return 'The code you supplied has now expired. Please try again to forgot your password.'
      case 'InvalidParameterException':
        return 'Username should be either an email or a phone number.'
      default:
        return `Raw message (please report): "${err.message}"`
    }
  } else {
    return 'Undefined error, please report.'
  }
}

interface AuthProviderProps {
  placeholder?: string // just a placholder - we need to pass on the children prop
}

export const AuthProvider = (props: PropsWithChildren<AuthProviderProps>) => {
  const [loginUserMutation] = useLoginUserMutation()
  const [signUpMutation] = useSignUpMutation()
  const [userByIdLazyQuery] = useUserByIdLazyQuery()
  const [forgotPasswordMutation] = useForgotPasswordMutation()

  const updateCurrentUserFromMutation = (
    mutationResult: UserType,
    currentToken: string | undefined = undefined,
  ): CurrentUser => {
    console.log(
      'updateCurrentUserFromMutation',
      mutationResult,
      currentToken,
      currentUser,
    )
    const newCurrentUser: CurrentUser = { ...currentUser }

    // The token may have been updated in the mutation result so use this by preference
    const token = mutationResult.token || currentUser.token || currentToken

    if (token && decodeToken(newCurrentUser, token)) {
      console.log('assigning userId', mutationResult.userId)
      newCurrentUser.userId = mutationResult.userId

      // If this is a login (i.e. existingUser is undefined) then save it
      if (!currentUser.loginUser) {
        newCurrentUser.loginUser = mutationResult
      } else {
        // First remove all null properties
        const source: UserType = { ...mutationResult }

        Object.keys(source).forEach((key) => {
          if (source[key as keyof typeof source] === null)
            delete source[key as keyof typeof source]
        })

        // Update the loginUser with any properties present in the mutation results
        newCurrentUser.loginUser = Object.assign(
          { ...newCurrentUser.loginUser },
          source,
        )
        console.log('after assign', newCurrentUser.loginUser)
      }
    }

    if (mutationResult.firstName)
      newCurrentUser.firstName = mutationResult.firstName
    if (mutationResult.lastName)
      newCurrentUser.lastName = mutationResult.lastName
    if (mutationResult.email) newCurrentUser.email = mutationResult.email
    if (mutationResult.isSubscriber !== null)
      newCurrentUser.isSubscriber = !!mutationResult.isSubscriber
    if (mutationResult.emailConfirmed !== null)
      newCurrentUser.isVerified = !!mutationResult.emailConfirmed
    if (mutationResult.avatar !== null)
      newCurrentUser.avatar = mutationResult.avatar

    // Note that we record the string representations of the privilege and roles
    if (mutationResult.userPrivilege)
      newCurrentUser.userPrivilege = mutationResult.userPrivilege.key

    if (mutationResult.userRoles) {
      newCurrentUser.userRoles = []
      mutationResult.userRoles.forEach((role) => {
        if (role?.key) newCurrentUser.userRoles?.push(role.key)
      })
    }

    const rules = mutationResult.userRules

    if (rules) {
      const unpackedRules = JSON.parse(rules)
      newCurrentUser.userAbility = createMongoAbility(unpackedRules, {
        detectSubjectType: (object) => object.__typename,
      })
    }

    newCurrentUser.isAuthenticated = isAuthenticated(newCurrentUser)

    console.log(
      'updateCurrentUserFromMutation setting currentUser',
      newCurrentUser,
    )
    setCurrentUser(newCurrentUser)

    /*
     * We return the newCurrentUser because some callers require it but the currentUser auth
     * won't update until the next React refresh cycle
     */
    return newCurrentUser
  }

  const register = async (
    email: string,
    password: string,
    subscribe: boolean,
  ) => {
    return new Promise<string>((resolve, reject) => {
      const newCurrentUser: CurrentUser = { isAuthenticated: false }

      // The user must check the box to accept terms and privacy policy to get to this point
      const now = getTimestampInSeconds()
      const signUpInput: SignUpInput = {
        email: email,
        password: password,
        acceptPrivacyAt: now,
        acceptTermsAt: now,
        isSubscriber: subscribe,
      }

      signUpMutation({
        variables: {
          user: signUpInput,
        },
      })
        .then((result) => {
          const signUpMutation: SignUpMutation | null | undefined = result.data

          console.log('after signUp')
          if (!signUpMutation) {
            console.log('error in signUp')
            reject()
          } else {
            console.log('signUp success, result:', result)

            // Retain the password through the sign-up workflow as we'll need it to authenticate in due course
            setPasswordState(password)

            // Record the userId - this will be used in ConfirmSignup
            newCurrentUser.userId = signUpMutation.signUpUser

            console.log('user is ' + newCurrentUser.userId)
            setCurrentUser(newCurrentUser)
            resolve('/confirmsignup')
          }
        })
        .catch((error) => {
          const apolloError: ApolloError = error as ApolloError

          // check if we're already in the process of sign up
          let signUpInProgress = false
          let userId: string | undefined = undefined

          apolloError.graphQLErrors.forEach((err: GraphQLError) => {
            if (err.extensions.code === AppErrorCode.SignUpInProgress) {
              signUpInProgress = true
              userId = err.extensions.userId as string
            }
          })

          if (signUpInProgress) {
            // Retain the password through the sign-up workflow as we'll need it to authenticate in due course
            setPasswordState(password)

            // Record the userId - this will be used in ConfirmSignup
            newCurrentUser.userId = userId
            setCurrentUser(newCurrentUser)

            resolve('/confirmsignup')
          } else {
            console.log(
              'error in signUp',
              apolloError.message,
              JSON.stringify(apolloError),
            )
            reject(friendlyErrorMessage(apolloError))
          }
        })
    })
  }

  const storeCurrentUserToken = (user: UserType): void => {
    const newCurrentUser = updateCurrentUserFromMutation(user)

    // Set the token cookie
    setCookie('token', newCurrentUser.token, { path: '/' })

    // Set the userId cookie - this will persist even after logout
    setCookie('userId', newCurrentUser.decodedToken?.userId, {
      path: '/',
    })

    // record token in localStorage
    if (newCurrentUser.token)
      localStorage.setItem('token', newCurrentUser.token)
  }

  /*
   * path is the page to navigate to
   */
  const authenticate = async (
    email: string,
    password: string,
    path: string | undefined = undefined,
  ): Promise<string> => {
    console.log('in authenticate')

    return new Promise<string>((resolve, reject) => {
      console.log('in authenticate promise', email, password, path)
      const loginInput: LoginType = {
        email: email,
        password: password,
      }

      loginUserMutation({
        variables: { loginInput: loginInput },
      })
        .then((result) => {
          const loginUserMutation: LoginUserMutation | null | undefined =
            result.data

          storeCurrentUserToken(loginUserMutation?.loginUser as UserType)

          resolve(path || '/')
        })
        .catch((error) => {
          const apolloError: ApolloError = error as ApolloError

          console.error(
            'on authenticate failure: ',
            JSON.stringify(apolloError, null, '  '),
          )
          console.log('resetting currentUser')
          setCurrentUser({ isAuthenticated: false })
          reject(friendlyErrorMessage(apolloError))
        })
    })
  }

  const forgotPassword = async (email: string): Promise<string> => {
    console.log('in forgotPassword')

    return new Promise<string>((resolve, reject) => {
      forgotPasswordMutation({
        variables: { user: email },
      })
        .then((result) => {
          resolve('/resetpassword')
        })
        .catch((error) => {
          const apolloError: ApolloError = error as ApolloError

          reject(friendlyErrorMessage(apolloError))
        })
    })
  }

  const retrieveUser = async (): Promise<CurrentUser> => {
    console.log('in retrieveUser, currentUser', currentUser)
    if (currentUser.isAuthenticated) {
      console.log('already have authenticated user and token is valid')
      return Promise.resolve(currentUser)
    } else {
      const token = cookies['token']

      if (token) {
        const newCurrentUser: CurrentUser = {
          isAuthenticated: false,
        }

        if (decodeToken(newCurrentUser, token)) {
          console.log('querying user by id', newCurrentUser)

          return userByIdLazyQuery({
            variables: { userId: newCurrentUser.userId || '' },
          })
            .then((result) => {
              console.log('returned from userByIdLazyQuery')
              const newCurrentUser = updateCurrentUserFromMutation(
                result?.data?.userById as UserType,
                token,
              )

              return newCurrentUser
            })
            .catch((e) => {
              console.log('userById error', e)
              return currentUser
            })
        }
      }

      return Promise.resolve(currentUser)
    }
  }

  const signOut = async () => {
    console.log('in generic signOut')
    return await new Promise<string>((resolve, reject) => {
      // Note that we retain the userId cookie
      removeCookie('token', { path: '/' })
      localStorage.removeItem('token')
      setCurrentUser({ isAuthenticated: false })

      resolve('/login')
    })
  }

  const [emailState, setEmailState] = useState('')
  const [passwordState, setPasswordState] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'userId'])
  const [currentUser, setCurrentUser] = useState<CurrentUser>({
    isAuthenticated: false,
  })

  return (
    <CookiesProvider>
      <AuthContext.Provider
        value={{
          register,
          authenticate,
          forgotPassword,
          retrieveUser,
          emailState,
          setEmailState,
          passwordState,
          setPasswordState,
          currentUser,
          setCurrentUser,
          updateCurrentUserFromMutation,
          storeCurrentUserToken,
          cookies,
          signOut,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </CookiesProvider>
  )
}
