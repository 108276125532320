import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AppErrorCode {
  AddToKeyTableInvalidNumber = 'ADD_TO_KEY_TABLE_INVALID_NUMBER',
  BadUserInput = 'BAD_USER_INPUT',
  ConfirmForgotPasswordErrorInCognito = 'CONFIRM_FORGOT_PASSWORD_ERROR_IN_COGNITO',
  ConfirmForgotPasswordInvalidConfirmCode = 'CONFIRM_FORGOT_PASSWORD_INVALID_CONFIRM_CODE',
  ConfirmForgotPasswordInvalidEmail = 'CONFIRM_FORGOT_PASSWORD_INVALID_EMAIL',
  ConfirmForgotPasswordInvalidPassword = 'CONFIRM_FORGOT_PASSWORD_INVALID_PASSWORD',
  ConfirmForgotPasswordMissingConfirmCode = 'CONFIRM_FORGOT_PASSWORD_MISSING_CONFIRM_CODE',
  ConfirmForgotPasswordMissingEmail = 'CONFIRM_FORGOT_PASSWORD_MISSING_EMAIL',
  ConfirmForgotPasswordMissingPassword = 'CONFIRM_FORGOT_PASSWORD_MISSING_PASSWORD',
  ConfirmForgotPasswordMissingUser = 'CONFIRM_FORGOT_PASSWORD_MISSING_USER',
  ConfirmForgotPasswordMissingUserInCognito = 'CONFIRM_FORGOT_PASSWORD_MISSING_USER_IN_COGNITO',
  CreateUserEmailExists = 'CREATE_USER_EMAIL_EXISTS',
  CreateUserEmailInvalid = 'CREATE_USER_EMAIL_INVALID',
  CreateUserFailed = 'CREATE_USER_FAILED',
  CreateUserIdExists = 'CREATE_USER_ID_EXISTS',
  DecodeKeyIdFail = 'DECODE_KEY_ID_FAIL',
  DeleteUserMissingId = 'DELETE_USER_MISSING_ID',
  DeleteUserMissingInDatabase = 'DELETE_USER_MISSING_IN_DATABASE',
  EditEmailCannotUpdate = 'EDIT_EMAIL_CANNOT_UPDATE',
  EditEmailErrorInCognito = 'EDIT_EMAIL_ERROR_IN_COGNITO',
  EditEmailInvalidEmail = 'EDIT_EMAIL_INVALID_EMAIL',
  EditEmailMissingEmail = 'EDIT_EMAIL_MISSING_EMAIL',
  EditEmailMissingId = 'EDIT_EMAIL_MISSING_ID',
  EditEmailMissingInput = 'EDIT_EMAIL_MISSING_INPUT',
  EditEmailMissingUser = 'EDIT_EMAIL_MISSING_USER',
  EditEmailMissingUserInCognito = 'EDIT_EMAIL_MISSING_USER_IN_COGNITO',
  EditPasswordErrorInCognito = 'EDIT_PASSWORD_ERROR_IN_COGNITO',
  EditPasswordMissingId = 'EDIT_PASSWORD_MISSING_ID',
  EditPasswordMissingInput = 'EDIT_PASSWORD_MISSING_INPUT',
  EditPasswordMissingInvalidNewPassword = 'EDIT_PASSWORD_MISSING_INVALID_NEW_PASSWORD',
  EditPasswordMissingNewPassword = 'EDIT_PASSWORD_MISSING_NEW_PASSWORD',
  EditPasswordMissingOldPassword = 'EDIT_PASSWORD_MISSING_OLD_PASSWORD',
  EditPasswordMissingUser = 'EDIT_PASSWORD_MISSING_USER',
  EditPasswordMissingUserInCognito = 'EDIT_PASSWORD_MISSING_USER_IN_COGNITO',
  EncodeKeyIdFail = 'ENCODE_KEY_ID_FAIL',
  ForgotPasswordErrorInCognito = 'FORGOT_PASSWORD_ERROR_IN_COGNITO',
  ForgotPasswordMissingEmail = 'FORGOT_PASSWORD_MISSING_EMAIL',
  ForgotPasswordNotSignedUp = 'FORGOT_PASSWORD_NOT_SIGNED_UP',
  GenerateKeyPairCryptoError = 'GENERATE_KEY_PAIR_CRYPTO_ERROR',
  GenerateKeyTableError = 'GENERATE_KEY_TABLE_ERROR',
  GetAllUsersError = 'GET_ALL_USERS_ERROR',
  GetAllUsersMissingUserInDatabase = 'GET_ALL_USERS_MISSING_USER_IN_DATABASE',
  GetRoleByIdDatabaseError = 'GET_ROLE_BY_ID_DATABASE_ERROR',
  GetRoleByIdError = 'GET_ROLE_BY_ID_ERROR',
  GetUsersByEmailError = 'GET_USERS_BY_EMAIL_ERROR',
  GetUsersByEmailInvalidEmail = 'GET_USERS_BY_EMAIL_INVALID_EMAIL',
  GetUsersByEmailMissingUserInDatabase = 'GET_USERS_BY_EMAIL_MISSING_USER_IN_DATABASE',
  GetUserByIdDatabaseError = 'GET_USER_BY_ID_DATABASE_ERROR',
  GetUserByIdMissing = 'GET_USER_BY_ID_MISSING',
  InstrumentNotFound = 'INSTRUMENT_NOT_FOUND',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  LoginFailed = 'LOGIN_FAILED',
  LoginMissingCognitoAuthentication = 'LOGIN_MISSING_COGNITO_AUTHENTICATION',
  LoginMissingEmail = 'LOGIN_MISSING_EMAIL',
  LoginMissingPassword = 'LOGIN_MISSING_PASSWORD',
  LoginMissingUserInput = 'LOGIN_MISSING_USER_INPUT',
  LoginMissingUserInCognito = 'LOGIN_MISSING_USER_IN_COGNITO',
  LoginMissingUserInDatabase = 'LOGIN_MISSING_USER_IN_DATABASE',
  LoginNotYetSignedUp = 'LOGIN_NOT_YET_SIGNED_UP',
  NotifyDuplicateCategory = 'NOTIFY_DUPLICATE_CATEGORY',
  NotifyInvalidCategory = 'NOTIFY_INVALID_CATEGORY',
  NotifyInvalidSubject = 'NOTIFY_INVALID_SUBJECT',
  NotifyInvalidUser = 'NOTIFY_INVALID_USER',
  NotifyMissingSubject = 'NOTIFY_MISSING_SUBJECT',
  NotifyUserModelUndefined = 'NOTIFY_USER_MODEL_UNDEFINED',
  PrivilegeNotFound = 'PRIVILEGE_NOT_FOUND',
  ProcessIdKeyInvalidName = 'PROCESS_ID_KEY_INVALID_NAME',
  ReadKeyTableCannotFindKeys = 'READ_KEY_TABLE_CANNOT_FIND_KEYS',
  ReadKeyTableCannotReadJson = 'READ_KEY_TABLE_CANNOT_READ_JSON',
  ReadKeyTableCannotReadYml = 'READ_KEY_TABLE_CANNOT_READ_YML',
  ReadKeyTableCryptoError = 'READ_KEY_TABLE_CRYPTO_ERROR',
  ReadKeyTableDuplicateKeyNum = 'READ_KEY_TABLE_DUPLICATE_KEY_NUM',
  ReadKeyTableIncompatibleKeys = 'READ_KEY_TABLE_INCOMPATIBLE_KEYS',
  ReadKeyTableInvalidStage = 'READ_KEY_TABLE_INVALID_STAGE',
  ReadKeyTableMismatchId = 'READ_KEY_TABLE_MISMATCH_ID',
  ReadKeyTableMismatchPublic = 'READ_KEY_TABLE_MISMATCH_PUBLIC',
  ReadKeyTableMissingFolder = 'READ_KEY_TABLE_MISSING_FOLDER',
  ReadKeyTableMissingKeyId = 'READ_KEY_TABLE_MISSING_KEY_ID',
  ReadKeyTableMissingKeyNum = 'READ_KEY_TABLE_MISSING_KEY_NUM',
  ReadKeyTableMissingStage = 'READ_KEY_TABLE_MISSING_STAGE',
  ResendCodeErrorInCognito = 'RESEND_CODE_ERROR_IN_COGNITO',
  ResendCodeMissingEmail = 'RESEND_CODE_MISSING_EMAIL',
  ResendCodeNotSignedUp = 'RESEND_CODE_NOT_SIGNED_UP',
  RoleNotFound = 'ROLE_NOT_FOUND',
  SignUpAlreadyVerified = 'SIGN_UP_ALREADY_VERIFIED',
  SignUpInvalidEmail = 'SIGN_UP_INVALID_EMAIL',
  SignUpInvalidPassword = 'SIGN_UP_INVALID_PASSWORD',
  SignUpInProgress = 'SIGN_UP_IN_PROGRESS',
  SignUpMissingEmail = 'SIGN_UP_MISSING_EMAIL',
  SignUpMissingPassword = 'SIGN_UP_MISSING_PASSWORD',
  SignUpMissingUserInput = 'SIGN_UP_MISSING_USER_INPUT',
  SignUpUserErrorInCognito = 'SIGN_UP_USER_ERROR_IN_COGNITO',
  SignUserCannotSign = 'SIGN_USER_CANNOT_SIGN',
  SignUserNoKeysForPrivilege = 'SIGN_USER_NO_KEYS_FOR_PRIVILEGE',
  SignUserNoPrivateKeyInSuppliedPair = 'SIGN_USER_NO_PRIVATE_KEY_IN_SUPPLIED_PAIR',
  SubscribeConfirmInvalidUserId = 'SUBSCRIBE_CONFIRM_INVALID_USER_ID',
  SubscribeError = 'SUBSCRIBE_ERROR',
  SubscribeInvalidEmail = 'SUBSCRIBE_INVALID_EMAIL',
  SubscribeMissingEmail = 'SUBSCRIBE_MISSING_EMAIL',
  SubscribeMissingUserInput = 'SUBSCRIBE_MISSING_USER_INPUT',
  Unauthenticated = 'UNAUTHENTICATED',
  Unauthorised = 'UNAUTHORISED',
  UpdateMissingRoleInDatabase = 'UPDATE_MISSING_ROLE_IN_DATABASE',
  UpdateMissingUserInDatabase = 'UPDATE_MISSING_USER_IN_DATABASE',
  UpdateRoleDatabaseError = 'UPDATE_ROLE_DATABASE_ERROR',
  UpdateRoleMissingId = 'UPDATE_ROLE_MISSING_ID',
  UpdateUserMissingId = 'UPDATE_USER_MISSING_ID',
  UploadUsersMissingModels = 'UPLOAD_USERS_MISSING_MODELS',
  UploadUsersNoHeadings = 'UPLOAD_USERS_NO_HEADINGS',
  UsersNotFound = 'USERS_NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND',
  VerifyEmailCannotUpdate = 'VERIFY_EMAIL_CANNOT_UPDATE',
  VerifyEmailErrorInCognito = 'VERIFY_EMAIL_ERROR_IN_COGNITO',
  VerifyEmailInvalidConfirmCode = 'VERIFY_EMAIL_INVALID_CONFIRM_CODE',
  VerifyEmailMissingConfirmCode = 'VERIFY_EMAIL_MISSING_CONFIRM_CODE',
  VerifyEmailMissingId = 'VERIFY_EMAIL_MISSING_ID',
  VerifyEmailMissingInput = 'VERIFY_EMAIL_MISSING_INPUT',
  VerifyEmailMissingUser = 'VERIFY_EMAIL_MISSING_USER',
  VerifyEmailMissingUserInCognito = 'VERIFY_EMAIL_MISSING_USER_IN_COGNITO',
  VerifyEmailNotInWorkflow = 'VERIFY_EMAIL_NOT_IN_WORKFLOW',
  VerifyTokenFailed = 'VERIFY_TOKEN_FAILED',
  VerifyTokenInvalid = 'VERIFY_TOKEN_INVALID',
  VerifyUserAlreadyVerified = 'VERIFY_USER_ALREADY_VERIFIED',
  VerifyUserErrorInCognito = 'VERIFY_USER_ERROR_IN_COGNITO',
  VerifyUserInvalidConfirmCode = 'VERIFY_USER_INVALID_CONFIRM_CODE',
  VerifyUserMissingConfirmCode = 'VERIFY_USER_MISSING_CONFIRM_CODE',
  VerifyUserMissingEmail = 'VERIFY_USER_MISSING_EMAIL',
  VerifyUserMissingPassword = 'VERIFY_USER_MISSING_PASSWORD',
  VerifyUserMissingUserInput = 'VERIFY_USER_MISSING_USER_INPUT',
  VerifyUserNotSignedUp = 'VERIFY_USER_NOT_SIGNED_UP',
  VerifyUserNoVerificationInProgress = 'VERIFY_USER_NO_VERIFICATION_IN_PROGRESS',
  WriteKeyTableCannotWriteJson = 'WRITE_KEY_TABLE_CANNOT_WRITE_JSON',
  WriteKeyTableCannotWriteYml = 'WRITE_KEY_TABLE_CANNOT_WRITE_YML',
  WriteKeyTableDuplicateKeyNum = 'WRITE_KEY_TABLE_DUPLICATE_KEY_NUM',
  WriteKeyTableInvalidStage = 'WRITE_KEY_TABLE_INVALID_STAGE',
  WriteKeyTableMissingFolder = 'WRITE_KEY_TABLE_MISSING_FOLDER',
  WriteKeyTableMissingStage = 'WRITE_KEY_TABLE_MISSING_STAGE'
}

export type ConfirmForgotInput = {
  confirmCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type CreateUserInput = {
  acceptMembershipAt?: InputMaybe<Scalars['Int']['input']>;
  acceptPrivacyAt?: InputMaybe<Scalars['Int']['input']>;
  acceptTermsAt?: InputMaybe<Scalars['Int']['input']>;
  acceptTrusteeAt?: InputMaybe<Scalars['Int']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  currentLoginAt?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['Int']['input']>;
  signUpAt?: InputMaybe<Scalars['Int']['input']>;
  signUpCategory?: InputMaybe<SignUpCategory>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userInstruments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userPrivilege?: InputMaybe<Scalars['ID']['input']>;
  userRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateUsersType = {
  __typename?: 'CreateUsersType';
  data?: Maybe<User>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type EditEmailInput = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type EditPasswordInput = {
  newPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type EditRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type EditUserInput = {
  acceptMembershipAt?: InputMaybe<Scalars['Int']['input']>;
  acceptPrivacyAt?: InputMaybe<Scalars['Int']['input']>;
  acceptTermsAt?: InputMaybe<Scalars['Int']['input']>;
  acceptTrusteeAt?: InputMaybe<Scalars['Int']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  currentLoginAt?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['Int']['input']>;
  signUpAt?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
  userInstruments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userPrivilege?: InputMaybe<Scalars['ID']['input']>;
  userRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ForgotInput = {
  email: Scalars['String']['input'];
};

export type Instrument = {
  __typename?: 'Instrument';
  description?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['ID']['output'];
  instrumentSection?: Maybe<Section>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  related?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export enum InstrumenteIdType {
  AcmeSiren = 'ACME_SIREN',
  AltoTrombone = 'ALTO_TROMBONE',
  Aluphone = 'ALUPHONE',
  BabyRattle = 'BABY_RATTLE',
  Baritone = 'BARITONE',
  Bass = 'BASS',
  Bassoon = 'BASSOON',
  BassDrum = 'BASS_DRUM',
  BassTrombone = 'BASS_TROMBONE',
  BellTree = 'BELL_TREE',
  Castanets = 'CASTANETS',
  Celesta = 'CELESTA',
  Cello = 'CELLO',
  Cimbasso = 'CIMBASSO',
  Clapper = 'CLAPPER',
  Clarinet = 'CLARINET',
  ClashCymbals = 'CLASH_CYMBALS',
  Claves = 'CLAVES',
  Colascione = 'COLASCIONE',
  Conductor = 'CONDUCTOR',
  Contrabassoon = 'CONTRABASSOON',
  ContrabassTrombone = 'CONTRABASS_TROMBONE',
  Contralto = 'CONTRALTO',
  ContraAltoClarinet = 'CONTRA_ALTO_CLARINET',
  CorAnglais = 'COR_ANGLAIS',
  Countertenor = 'COUNTERTENOR',
  Cowbell = 'COWBELL',
  Crotales = 'CROTALES',
  Cymbal = 'CYMBAL',
  DoubleBass = 'DOUBLE_BASS',
  Flute = 'FLUTE',
  Fortepiano = 'FORTEPIANO',
  FrenchHorn = 'FRENCH_HORN',
  Glockenspiel = 'GLOCKENSPIEL',
  Gong = 'GONG',
  Guiro = 'GUIRO',
  Guitar = 'GUITAR',
  Harp = 'HARP',
  JamBlock = 'JAM_BLOCK',
  KeyboardGlockenspiel = 'KEYBOARD_GLOCKENSPIEL',
  Lute = 'LUTE',
  Mandolin = 'MANDOLIN',
  Maraca = 'MARACA',
  MezzoSoprano = 'MEZZO_SOPRANO',
  NaturalHorn = 'NATURAL_HORN',
  NaturalTrumpet = 'NATURAL_TRUMPET',
  Oboe = 'OBOE',
  Organ = 'ORGAN',
  PedalHarp = 'PEDAL_HARP',
  Percussion = 'PERCUSSION',
  Piano = 'PIANO',
  Piccolo = 'PICCOLO',
  PipeOrgan = 'PIPE_ORGAN',
  Ratchet = 'RATCHET',
  Saxophone = 'SAXOPHONE',
  Serpent = 'SERPENT',
  SnareDrum = 'SNARE_DRUM',
  Soprano = 'SOPRANO',
  Tambourine = 'TAMBOURINE',
  TempleBlocks = 'TEMPLE_BLOCKS',
  Tenor = 'TENOR',
  TenorDrum = 'TENOR_DRUM',
  Theorbo = 'THEORBO',
  ThunderSheet = 'THUNDER_SHEET',
  Timpani = 'TIMPANI',
  TomDrum = 'TOM_DRUM',
  Treble = 'TREBLE',
  Triangle = 'TRIANGLE',
  Trombone = 'TROMBONE',
  Trumpet = 'TRUMPET',
  Tuba = 'TUBA',
  TubularBells = 'TUBULAR_BELLS',
  Viola = 'VIOLA',
  Violin = 'VIOLIN',
  Woodblock = 'WOODBLOCK',
  Xylophone = 'XYLOPHONE'
}

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmForgotPassword: User;
  createUser: User;
  createUsers: Array<Maybe<CreateUsersType>>;
  deleteUser?: Maybe<Scalars['String']['output']>;
  editEmail: Scalars['String']['output'];
  editPassword: Scalars['String']['output'];
  forgotPassword?: Maybe<Scalars['Boolean']['output']>;
  loginUser: User;
  resendConfirmationCode?: Maybe<Scalars['String']['output']>;
  signUpUser: Scalars['String']['output'];
  subscribe: Scalars['String']['output'];
  subscribeConfirm?: Maybe<Scalars['Boolean']['output']>;
  unsubscribe?: Maybe<Scalars['Boolean']['output']>;
  updateUser: User;
  uploadUsers: UploadUsersOutput;
  verifyEmail: Scalars['String']['output'];
  verifyUser: User;
};


export type MutationConfirmForgotPasswordArgs = {
  confirmForgotInput?: InputMaybe<ConfirmForgotInput>;
};


export type MutationCreateUserArgs = {
  createUserInput?: InputMaybe<CreateUserInput>;
};


export type MutationCreateUsersArgs = {
  createUsersInput?: InputMaybe<Array<InputMaybe<CreateUserInput>>>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationEditEmailArgs = {
  editEmailInput?: InputMaybe<EditEmailInput>;
};


export type MutationEditPasswordArgs = {
  editPasswordInput?: InputMaybe<EditPasswordInput>;
};


export type MutationForgotPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginUserArgs = {
  loginInput?: InputMaybe<LoginInput>;
};


export type MutationResendConfirmationCodeArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignUpUserArgs = {
  signUpInput?: InputMaybe<SignUpInput>;
};


export type MutationSubscribeArgs = {
  subscribeInput?: InputMaybe<SubscribeInput>;
};


export type MutationSubscribeConfirmArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUnsubscribeArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  editUserInput?: InputMaybe<EditUserInput>;
};


export type MutationUploadUsersArgs = {
  uploadUsersInput?: InputMaybe<UploadUsersInput>;
};


export type MutationVerifyEmailArgs = {
  verifyEmailInput?: InputMaybe<VerifyEmailInput>;
};


export type MutationVerifyUserArgs = {
  verifyInput?: InputMaybe<VerifyInput>;
};

export type Privilege = {
  __typename?: 'Privilege';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  privilegeId: Scalars['ID']['output'];
};

export enum PrivilegeIdType {
  Admin = 'ADMIN',
  None = 'NONE',
  Standard = 'STANDARD',
  Super = 'SUPER'
}

export type Query = {
  __typename?: 'Query';
  instrumentById?: Maybe<Instrument>;
  instruments?: Maybe<Array<Maybe<Instrument>>>;
  loggedInUser: User;
  privilegeById?: Maybe<Privilege>;
  privileges?: Maybe<Array<Maybe<Privilege>>>;
  roleById?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  sectionById?: Maybe<Section>;
  sections?: Maybe<Array<Maybe<Section>>>;
  userByEmail?: Maybe<User>;
  userById?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  usersByIds?: Maybe<Array<Maybe<User>>>;
  usersByPrivileges?: Maybe<Array<Maybe<User>>>;
  usersByRoles?: Maybe<Array<Maybe<User>>>;
  version: Version;
};


export type QueryInstrumentByIdArgs = {
  instrumentId: Scalars['ID']['input'];
};


export type QueryLoggedInUserArgs = {
  token: Scalars['String']['input'];
};


export type QueryPrivilegeByIdArgs = {
  privilegeId: Scalars['ID']['input'];
};


export type QueryRoleByIdArgs = {
  roleId: Scalars['ID']['input'];
};


export type QuerySectionByIdArgs = {
  sectionId: Scalars['ID']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersByIdsArgs = {
  userIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryUsersByPrivilegesArgs = {
  privilegeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryUsersByRolesArgs = {
  roleIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type ResendConfirmationCodeInput = {
  email: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  duties?: Maybe<Scalars['String']['output']>;
  isCommitteeRole?: Maybe<Scalars['Boolean']['output']>;
  isMemberRole?: Maybe<Scalars['Boolean']['output']>;
  isPaidRole?: Maybe<Scalars['Boolean']['output']>;
  isSupporterRole?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['ID']['output'];
  roleUsers?: Maybe<Array<Maybe<User>>>;
  skillsRequired?: Maybe<Scalars['String']['output']>;
  timeRequired?: Maybe<Scalars['String']['output']>;
};

export enum RoleIdType {
  ArtisticDirector = 'ARTISTIC_DIRECTOR',
  Bookkeeper = 'BOOKKEEPER',
  Chair = 'CHAIR',
  CharityCommissionCoordinator = 'CHARITY_COMMISSION_COORDINATOR',
  ClassicalSheffieldCoordinator = 'CLASSICAL_SHEFFIELD_COORDINATOR',
  Committee = 'COMMITTEE',
  ConcertHelper = 'CONCERT_HELPER',
  ConcertManager = 'CONCERT_MANAGER',
  ContentEditor = 'CONTENT_EDITOR',
  Copywriter = 'COPYWRITER',
  DigitalMusicCoordinator = 'DIGITAL_MUSIC_COORDINATOR',
  EdiLead = 'EDI_LEAD',
  Extra = 'EXTRA',
  FixingSecretary = 'FIXING_SECRETARY',
  Friend = 'FRIEND',
  FriendsRecruitment = 'FRIENDS_RECRUITMENT',
  FriendsRelations = 'FRIENDS_RELATIONS',
  FrontOfHouseCoordinator = 'FRONT_OF_HOUSE_COORDINATOR',
  FundraisingCoordinator = 'FUNDRAISING_COORDINATOR',
  GiftAidAdministrator = 'GIFT_AID_ADMINISTRATOR',
  Keyholder = 'KEYHOLDER',
  Leader = 'LEADER',
  Librarian = 'LIBRARIAN',
  MakingMusicCoordinator = 'MAKING_MUSIC_COORDINATOR',
  MarketingSecretary = 'MARKETING_SECRETARY',
  MediaCoordinator = 'MEDIA_COORDINATOR',
  Member = 'MEMBER',
  MembershipSecretary = 'MEMBERSHIP_SECRETARY',
  MemberRecruitment = 'MEMBER_RECRUITMENT',
  NewsletterCoordinator = 'NEWSLETTER_COORDINATOR',
  NewMemberBuddy = 'NEW_MEMBER_BUDDY',
  NonStringsFixer = 'NON_STRINGS_FIXER',
  NonStringsMemberRelations = 'NON_STRINGS_MEMBER_RELATIONS',
  PartnershipsCoordinator = 'PARTNERSHIPS_COORDINATOR',
  PosterPlacement = 'POSTER_PLACEMENT',
  PrintedMusicCoordinator = 'PRINTED_MUSIC_COORDINATOR',
  ProgrammeProduction = 'PROGRAMME_PRODUCTION',
  ProofReader = 'PROOF_READER',
  PublicityCoordinator = 'PUBLICITY_COORDINATOR',
  PublicityProduction = 'PUBLICITY_PRODUCTION',
  RepertoireCoordinator = 'REPERTOIRE_COORDINATOR',
  RiskOfficer = 'RISK_OFFICER',
  SafeguardingLead = 'SAFEGUARDING_LEAD',
  SafeguardingOfficer = 'SAFEGUARDING_OFFICER',
  Secretary = 'SECRETARY',
  SocialMediaCoordinator = 'SOCIAL_MEDIA_COORDINATOR',
  StringsFixer = 'STRINGS_FIXER',
  StringsMemberRelations = 'STRINGS_MEMBER_RELATIONS',
  Supporter = 'SUPPORTER',
  TicketSales = 'TICKET_SALES',
  Treasurer = 'TREASURER',
  Trustee = 'TRUSTEE',
  VenueBooking = 'VENUE_BOOKING',
  Webmaster = 'WEBMASTER',
  WebsiteCoordinator = 'WEBSITE_COORDINATOR'
}

export type Section = {
  __typename?: 'Section';
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sectionId: Scalars['ID']['output'];
};

export enum SectionIdType {
  Brass = 'BRASS',
  Conductor = 'CONDUCTOR',
  Keyboard = 'KEYBOARD',
  Other = 'OTHER',
  Percussion = 'PERCUSSION',
  String = 'STRING',
  Voice = 'VOICE',
  Woodwind = 'WOODWIND'
}

export enum SignUpCategory {
  ExtraApply = 'EXTRA_APPLY',
  FriendApply = 'FRIEND_APPLY',
  MemberApply = 'MEMBER_APPLY'
}

export type SignUpInput = {
  acceptPrivacyAt?: InputMaybe<Scalars['Int']['input']>;
  acceptTermsAt?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  isSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  signUpCategory?: InputMaybe<SignUpCategory>;
};

export type SubscribeInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadUsersInput = {
  data: Array<InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>>;
  filename: Scalars['String']['input'];
  validate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UploadUsersOutput = {
  __typename?: 'UploadUsersOutput';
  userIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  validation?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
};

export type User = {
  __typename?: 'User';
  acceptMembershipAt?: Maybe<Scalars['Int']['output']>;
  acceptPrivacyAt?: Maybe<Scalars['Int']['output']>;
  acceptTermsAt?: Maybe<Scalars['Int']['output']>;
  acceptTrusteeAt?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  cognitoUser?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<User>;
  currentLoginAt?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailConfirmationInProgress?: Maybe<Scalars['Boolean']['output']>;
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isSubscriber?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  signUpAt?: Maybe<Scalars['Int']['output']>;
  signUpCategory?: Maybe<SignUpCategory>;
  token?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  userInstruments?: Maybe<Array<Maybe<Instrument>>>;
  userPrivilege?: Maybe<Privilege>;
  userRoles?: Maybe<Array<Maybe<Role>>>;
  userRules?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifyEmailInput = {
  confirmCode: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type VerifyInput = {
  confirmCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Version = {
  __typename?: 'Version';
  versionNumber?: Maybe<Scalars['String']['output']>;
};

export type ContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', createdAt?: number | null, email?: string | null, emailConfirmed?: boolean | null, firstName?: string | null, lastLoginAt?: number | null, currentLoginAt?: number | null, lastName?: string | null, modifiedAt?: number | null, signUpAt?: number | null, userId: string, isSubscriber?: boolean | null, avatar?: string | null, verified?: boolean | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null, key?: string | null, instrumentSection?: { __typename?: 'Section', sectionId: string, name?: string | null, key?: string | null } | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', name?: string | null, key?: string | null, privilegeId: string } | null, userRoles?: Array<{ __typename?: 'Role', name?: string | null, key?: string | null, roleId: string } | null> | null } | null> | null };

export type CreateUserMutationVariables = Exact<{
  user?: InputMaybe<CreateUserInput>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', createdAt?: number | null, email?: string | null, emailConfirmed?: boolean | null, firstName?: string | null, lastLoginAt?: number | null, currentLoginAt?: number | null, lastName?: string | null, modifiedAt?: number | null, signUpAt?: number | null, userId: string, verified?: boolean | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null, key?: string | null, instrumentSection?: { __typename?: 'Section', sectionId: string, name?: string | null, key?: string | null } | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', name?: string | null, key?: string | null, privilegeId: string } | null, userRoles?: Array<{ __typename?: 'Role', name?: string | null, key?: string | null, roleId: string } | null> | null } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: string | null };

export type InstrumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type InstrumentsQuery = { __typename?: 'Query', instruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null, key?: string | null, description?: string | null, instrumentSection?: { __typename?: 'Section', sectionId: string, name?: string | null, key?: string | null } | null } | null> | null };

export type PrivilegesQueryVariables = Exact<{ [key: string]: never; }>;


export type PrivilegesQuery = { __typename?: 'Query', privileges?: Array<{ __typename?: 'Privilege', privilegeId: string, name?: string | null, key?: string | null, description?: string | null } | null> | null };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles?: Array<{ __typename?: 'Role', roleId: string, name?: string | null, key?: string | null, priority?: number | null, purpose?: string | null, description?: string | null, duties?: string | null, timeRequired?: string | null, skillsRequired?: string | null, isMemberRole?: boolean | null, isCommitteeRole?: boolean | null, isSupporterRole?: boolean | null, isPaidRole?: boolean | null, roleUsers?: Array<{ __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null } | null> | null } | null> | null };

export type SectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SectionsQuery = { __typename?: 'Query', sections?: Array<{ __typename?: 'Section', sectionId: string, name?: string | null, key?: string | null, description?: string | null } | null> | null };

export type SubscribeMutationVariables = Exact<{
  subscribeInput?: InputMaybe<SubscribeInput>;
}>;


export type SubscribeMutation = { __typename?: 'Mutation', subscribe: string };

export type SubscribeConfirmMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type SubscribeConfirmMutation = { __typename?: 'Mutation', subscribeConfirm?: boolean | null };

export type UnsubscribeMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UnsubscribeMutation = { __typename?: 'Mutation', unsubscribe?: boolean | null };

export type UpdateUserMutationVariables = Exact<{
  editUserInput?: InputMaybe<EditUserInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null, email?: string | null, token?: string | null, emailConfirmed?: boolean | null, isSubscriber?: boolean | null, avatar?: string | null, signUpCategory?: SignUpCategory | null, lastLoginAt?: number | null, currentLoginAt?: number | null, acceptMembershipAt?: number | null, acceptTrusteeAt?: number | null, acceptTermsAt?: number | null, acceptPrivacyAt?: number | null, userRules?: string | null, userRoles?: Array<{ __typename?: 'Role', roleId: string, name?: string | null, key?: string | null, priority?: number | null, purpose?: string | null, description?: string | null, duties?: string | null, timeRequired?: string | null, skillsRequired?: string | null, isMemberRole?: boolean | null, isCommitteeRole?: boolean | null, isSupporterRole?: boolean | null, isPaidRole?: boolean | null, roleUsers?: Array<{ __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null } | null> | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', privilegeId: string, name?: string | null, key?: string | null } | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null } | null> | null } };

export type UploadUsersMutationVariables = Exact<{
  uploadUsersInput?: InputMaybe<UploadUsersInput>;
}>;


export type UploadUsersMutation = { __typename?: 'Mutation', uploadUsers: { __typename?: 'UploadUsersOutput', userIds?: Array<string | null> | null, validation?: Array<Array<string | null> | null> | null } };

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = { __typename?: 'Query', version: { __typename?: 'Version', versionNumber?: string | null } };

export type ConfirmForgotPasswordMutationVariables = Exact<{
  user?: InputMaybe<ConfirmForgotInput>;
}>;


export type ConfirmForgotPasswordMutation = { __typename?: 'Mutation', confirmForgotPassword: { __typename?: 'User', userId: string, email?: string | null } };

export type EditEmailMutationVariables = Exact<{
  user?: InputMaybe<EditEmailInput>;
}>;


export type EditEmailMutation = { __typename?: 'Mutation', editEmail: string };

export type EditPasswordMutationVariables = Exact<{
  editPasswordInput?: InputMaybe<EditPasswordInput>;
}>;


export type EditPasswordMutation = { __typename?: 'Mutation', editPassword: string };

export type ForgotPasswordMutationVariables = Exact<{
  user?: InputMaybe<Scalars['String']['input']>;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: boolean | null };

export type LoginUserMutationVariables = Exact<{
  loginInput?: InputMaybe<LoginInput>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser: { __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null, email?: string | null, token?: string | null, emailConfirmed?: boolean | null, isSubscriber?: boolean | null, avatar?: string | null, signUpCategory?: SignUpCategory | null, lastLoginAt?: number | null, currentLoginAt?: number | null, acceptMembershipAt?: number | null, acceptTrusteeAt?: number | null, acceptTermsAt?: number | null, acceptPrivacyAt?: number | null, userRules?: string | null, userRoles?: Array<{ __typename?: 'Role', roleId: string, name?: string | null, key?: string | null, priority?: number | null, purpose?: string | null, description?: string | null, duties?: string | null, timeRequired?: string | null, skillsRequired?: string | null, isMemberRole?: boolean | null, isCommitteeRole?: boolean | null, isSupporterRole?: boolean | null, isPaidRole?: boolean | null, roleUsers?: Array<{ __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null } | null> | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', privilegeId: string, name?: string | null, key?: string | null } | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null } | null> | null } };

export type ResendConfirmationCodeMutationVariables = Exact<{
  user?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResendConfirmationCodeMutation = { __typename?: 'Mutation', resendConfirmationCode?: string | null };

export type SignUpMutationVariables = Exact<{
  user?: InputMaybe<SignUpInput>;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUpUser: string };

export type UserByIdQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserByIdQuery = { __typename?: 'Query', userById?: { __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null, email?: string | null, emailConfirmed?: boolean | null, isSubscriber?: boolean | null, avatar?: string | null, signUpCategory?: SignUpCategory | null, lastLoginAt?: number | null, currentLoginAt?: number | null, acceptMembershipAt?: number | null, acceptTrusteeAt?: number | null, acceptTermsAt?: number | null, acceptPrivacyAt?: number | null, userRules?: string | null, userRoles?: Array<{ __typename?: 'Role', roleId: string, name?: string | null, key?: string | null, priority?: number | null, purpose?: string | null, description?: string | null, duties?: string | null, timeRequired?: string | null, skillsRequired?: string | null, isMemberRole?: boolean | null, isCommitteeRole?: boolean | null, isSupporterRole?: boolean | null, isPaidRole?: boolean | null, roleUsers?: Array<{ __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null } | null> | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', privilegeId: string, name?: string | null, key?: string | null } | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null } | null> | null } | null };

export type VerifyEmailMutationVariables = Exact<{
  user?: InputMaybe<VerifyEmailInput>;
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: string };

export type VerifyUserMutationVariables = Exact<{
  user?: InputMaybe<VerifyInput>;
}>;


export type VerifyUserMutation = { __typename?: 'Mutation', verifyUser: { __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null, email?: string | null, token?: string | null, emailConfirmed?: boolean | null, isSubscriber?: boolean | null, avatar?: string | null, signUpCategory?: SignUpCategory | null, lastLoginAt?: number | null, currentLoginAt?: number | null, acceptMembershipAt?: number | null, acceptTrusteeAt?: number | null, acceptTermsAt?: number | null, acceptPrivacyAt?: number | null, userRules?: string | null, userRoles?: Array<{ __typename?: 'Role', roleId: string, name?: string | null, key?: string | null, priority?: number | null, purpose?: string | null, description?: string | null, duties?: string | null, timeRequired?: string | null, skillsRequired?: string | null, isMemberRole?: boolean | null, isCommitteeRole?: boolean | null, isSupporterRole?: boolean | null, isPaidRole?: boolean | null, roleUsers?: Array<{ __typename?: 'User', userId: string, firstName?: string | null, lastName?: string | null } | null> | null } | null> | null, userPrivilege?: { __typename?: 'Privilege', privilegeId: string, name?: string | null, key?: string | null } | null, userInstruments?: Array<{ __typename?: 'Instrument', instrumentId: string, name?: string | null } | null> | null } };


export const ContactsDocument = gql`
    query Contacts {
  users {
    createdAt
    email
    emailConfirmed
    firstName
    lastLoginAt
    currentLoginAt
    lastName
    modifiedAt
    signUpAt
    userId
    isSubscriber
    avatar
    userInstruments {
      instrumentId
      name
      key
      instrumentSection {
        sectionId
        name
        key
      }
    }
    userPrivilege {
      name
      key
      privilegeId
    }
    userRoles {
      name
      key
      roleId
    }
    verified
  }
}
    `;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export function useContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsSuspenseQueryHookResult = ReturnType<typeof useContactsSuspenseQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: CreateUserInput) {
  createUser(createUserInput: $user) {
    createdAt
    email
    emailConfirmed
    firstName
    lastLoginAt
    currentLoginAt
    lastName
    modifiedAt
    signUpAt
    userId
    userInstruments {
      instrumentId
      name
      key
      instrumentSection {
        sectionId
        name
        key
      }
    }
    userPrivilege {
      name
      key
      privilegeId
    }
    userRoles {
      name
      key
      roleId
    }
    verified
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: ID!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InstrumentsDocument = gql`
    query Instruments {
  instruments {
    instrumentId
    name
    key
    instrumentSection {
      sectionId
      name
      key
    }
    description
  }
}
    `;

/**
 * __useInstrumentsQuery__
 *
 * To run a query within a React component, call `useInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstrumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstrumentsQuery(baseOptions?: Apollo.QueryHookOptions<InstrumentsQuery, InstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstrumentsQuery, InstrumentsQueryVariables>(InstrumentsDocument, options);
      }
export function useInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstrumentsQuery, InstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstrumentsQuery, InstrumentsQueryVariables>(InstrumentsDocument, options);
        }
export function useInstrumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InstrumentsQuery, InstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InstrumentsQuery, InstrumentsQueryVariables>(InstrumentsDocument, options);
        }
export type InstrumentsQueryHookResult = ReturnType<typeof useInstrumentsQuery>;
export type InstrumentsLazyQueryHookResult = ReturnType<typeof useInstrumentsLazyQuery>;
export type InstrumentsSuspenseQueryHookResult = ReturnType<typeof useInstrumentsSuspenseQuery>;
export type InstrumentsQueryResult = Apollo.QueryResult<InstrumentsQuery, InstrumentsQueryVariables>;
export const PrivilegesDocument = gql`
    query Privileges {
  privileges {
    privilegeId
    name
    key
    description
  }
}
    `;

/**
 * __usePrivilegesQuery__
 *
 * To run a query within a React component, call `usePrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivilegesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivilegesQuery(baseOptions?: Apollo.QueryHookOptions<PrivilegesQuery, PrivilegesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivilegesQuery, PrivilegesQueryVariables>(PrivilegesDocument, options);
      }
export function usePrivilegesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivilegesQuery, PrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivilegesQuery, PrivilegesQueryVariables>(PrivilegesDocument, options);
        }
export function usePrivilegesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PrivilegesQuery, PrivilegesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrivilegesQuery, PrivilegesQueryVariables>(PrivilegesDocument, options);
        }
export type PrivilegesQueryHookResult = ReturnType<typeof usePrivilegesQuery>;
export type PrivilegesLazyQueryHookResult = ReturnType<typeof usePrivilegesLazyQuery>;
export type PrivilegesSuspenseQueryHookResult = ReturnType<typeof usePrivilegesSuspenseQuery>;
export type PrivilegesQueryResult = Apollo.QueryResult<PrivilegesQuery, PrivilegesQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    roleId
    name
    key
    priority
    purpose
    description
    duties
    timeRequired
    skillsRequired
    isMemberRole
    isCommitteeRole
    isSupporterRole
    isPaidRole
    roleUsers {
      userId
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SectionsDocument = gql`
    query Sections {
  sections {
    sectionId
    name
    key
    description
  }
}
    `;

/**
 * __useSectionsQuery__
 *
 * To run a query within a React component, call `useSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionsQuery(baseOptions?: Apollo.QueryHookOptions<SectionsQuery, SectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectionsQuery, SectionsQueryVariables>(SectionsDocument, options);
      }
export function useSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionsQuery, SectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectionsQuery, SectionsQueryVariables>(SectionsDocument, options);
        }
export function useSectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SectionsQuery, SectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SectionsQuery, SectionsQueryVariables>(SectionsDocument, options);
        }
export type SectionsQueryHookResult = ReturnType<typeof useSectionsQuery>;
export type SectionsLazyQueryHookResult = ReturnType<typeof useSectionsLazyQuery>;
export type SectionsSuspenseQueryHookResult = ReturnType<typeof useSectionsSuspenseQuery>;
export type SectionsQueryResult = Apollo.QueryResult<SectionsQuery, SectionsQueryVariables>;
export const SubscribeDocument = gql`
    mutation Subscribe($subscribeInput: SubscribeInput) {
  subscribe(subscribeInput: $subscribeInput)
}
    `;
export type SubscribeMutationFn = Apollo.MutationFunction<SubscribeMutation, SubscribeMutationVariables>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      subscribeInput: // value for 'subscribeInput'
 *   },
 * });
 */
export function useSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, options);
      }
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const SubscribeConfirmDocument = gql`
    mutation SubscribeConfirm($userId: ID!) {
  subscribeConfirm(userId: $userId)
}
    `;
export type SubscribeConfirmMutationFn = Apollo.MutationFunction<SubscribeConfirmMutation, SubscribeConfirmMutationVariables>;

/**
 * __useSubscribeConfirmMutation__
 *
 * To run a mutation, you first call `useSubscribeConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeConfirmMutation, { data, loading, error }] = useSubscribeConfirmMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscribeConfirmMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeConfirmMutation, SubscribeConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeConfirmMutation, SubscribeConfirmMutationVariables>(SubscribeConfirmDocument, options);
      }
export type SubscribeConfirmMutationHookResult = ReturnType<typeof useSubscribeConfirmMutation>;
export type SubscribeConfirmMutationResult = Apollo.MutationResult<SubscribeConfirmMutation>;
export type SubscribeConfirmMutationOptions = Apollo.BaseMutationOptions<SubscribeConfirmMutation, SubscribeConfirmMutationVariables>;
export const UnsubscribeDocument = gql`
    mutation Unsubscribe($userId: ID!) {
  unsubscribe(userId: $userId)
}
    `;
export type UnsubscribeMutationFn = Apollo.MutationFunction<UnsubscribeMutation, UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, options);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($editUserInput: EditUserInput) {
  updateUser(editUserInput: $editUserInput) {
    userId
    firstName
    lastName
    email
    token
    emailConfirmed
    isSubscriber
    avatar
    signUpCategory
    lastLoginAt
    currentLoginAt
    acceptMembershipAt
    acceptTrusteeAt
    acceptTermsAt
    acceptPrivacyAt
    userRules
    userRoles {
      roleId
      name
      key
      priority
      purpose
      description
      duties
      timeRequired
      skillsRequired
      isMemberRole
      isCommitteeRole
      isSupporterRole
      isPaidRole
      roleUsers {
        userId
        firstName
        lastName
      }
    }
    userPrivilege {
      privilegeId
      name
      key
    }
    userInstruments {
      instrumentId
      name
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      editUserInput: // value for 'editUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadUsersDocument = gql`
    mutation UploadUsers($uploadUsersInput: UploadUsersInput) {
  uploadUsers(uploadUsersInput: $uploadUsersInput) {
    userIds
    validation
  }
}
    `;
export type UploadUsersMutationFn = Apollo.MutationFunction<UploadUsersMutation, UploadUsersMutationVariables>;

/**
 * __useUploadUsersMutation__
 *
 * To run a mutation, you first call `useUploadUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUsersMutation, { data, loading, error }] = useUploadUsersMutation({
 *   variables: {
 *      uploadUsersInput: // value for 'uploadUsersInput'
 *   },
 * });
 */
export function useUploadUsersMutation(baseOptions?: Apollo.MutationHookOptions<UploadUsersMutation, UploadUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUsersMutation, UploadUsersMutationVariables>(UploadUsersDocument, options);
      }
export type UploadUsersMutationHookResult = ReturnType<typeof useUploadUsersMutation>;
export type UploadUsersMutationResult = Apollo.MutationResult<UploadUsersMutation>;
export type UploadUsersMutationOptions = Apollo.BaseMutationOptions<UploadUsersMutation, UploadUsersMutationVariables>;
export const VersionDocument = gql`
    query Version {
  version {
    versionNumber
  }
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
      }
export function useVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export function useVersionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionSuspenseQueryHookResult = ReturnType<typeof useVersionSuspenseQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const ConfirmForgotPasswordDocument = gql`
    mutation ConfirmForgotPassword($user: ConfirmForgotInput) {
  confirmForgotPassword(confirmForgotInput: $user) {
    userId
    email
  }
}
    `;
export type ConfirmForgotPasswordMutationFn = Apollo.MutationFunction<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>;

/**
 * __useConfirmForgotPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmForgotPasswordMutation, { data, loading, error }] = useConfirmForgotPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useConfirmForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>(ConfirmForgotPasswordDocument, options);
      }
export type ConfirmForgotPasswordMutationHookResult = ReturnType<typeof useConfirmForgotPasswordMutation>;
export type ConfirmForgotPasswordMutationResult = Apollo.MutationResult<ConfirmForgotPasswordMutation>;
export type ConfirmForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>;
export const EditEmailDocument = gql`
    mutation editEmail($user: EditEmailInput) {
  editEmail(editEmailInput: $user)
}
    `;
export type EditEmailMutationFn = Apollo.MutationFunction<EditEmailMutation, EditEmailMutationVariables>;

/**
 * __useEditEmailMutation__
 *
 * To run a mutation, you first call `useEditEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmailMutation, { data, loading, error }] = useEditEmailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useEditEmailMutation(baseOptions?: Apollo.MutationHookOptions<EditEmailMutation, EditEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEmailMutation, EditEmailMutationVariables>(EditEmailDocument, options);
      }
export type EditEmailMutationHookResult = ReturnType<typeof useEditEmailMutation>;
export type EditEmailMutationResult = Apollo.MutationResult<EditEmailMutation>;
export type EditEmailMutationOptions = Apollo.BaseMutationOptions<EditEmailMutation, EditEmailMutationVariables>;
export const EditPasswordDocument = gql`
    mutation EditPassword($editPasswordInput: EditPasswordInput) {
  editPassword(editPasswordInput: $editPasswordInput)
}
    `;
export type EditPasswordMutationFn = Apollo.MutationFunction<EditPasswordMutation, EditPasswordMutationVariables>;

/**
 * __useEditPasswordMutation__
 *
 * To run a mutation, you first call `useEditPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPasswordMutation, { data, loading, error }] = useEditPasswordMutation({
 *   variables: {
 *      editPasswordInput: // value for 'editPasswordInput'
 *   },
 * });
 */
export function useEditPasswordMutation(baseOptions?: Apollo.MutationHookOptions<EditPasswordMutation, EditPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPasswordMutation, EditPasswordMutationVariables>(EditPasswordDocument, options);
      }
export type EditPasswordMutationHookResult = ReturnType<typeof useEditPasswordMutation>;
export type EditPasswordMutationResult = Apollo.MutationResult<EditPasswordMutation>;
export type EditPasswordMutationOptions = Apollo.BaseMutationOptions<EditPasswordMutation, EditPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($user: String) {
  forgotPassword(email: $user)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($loginInput: LoginInput) {
  loginUser(loginInput: $loginInput) {
    userId
    firstName
    lastName
    email
    token
    emailConfirmed
    isSubscriber
    avatar
    signUpCategory
    lastLoginAt
    currentLoginAt
    acceptMembershipAt
    acceptTrusteeAt
    acceptTermsAt
    acceptPrivacyAt
    userRules
    userRoles {
      roleId
      name
      key
      priority
      purpose
      description
      duties
      timeRequired
      skillsRequired
      isMemberRole
      isCommitteeRole
      isSupporterRole
      isPaidRole
      roleUsers {
        userId
        firstName
        lastName
      }
    }
    userPrivilege {
      privilegeId
      name
      key
    }
    userInstruments {
      instrumentId
      name
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const ResendConfirmationCodeDocument = gql`
    mutation ResendConfirmationCode($user: String) {
  resendConfirmationCode(email: $user)
}
    `;
export type ResendConfirmationCodeMutationFn = Apollo.MutationFunction<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>;

/**
 * __useResendConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useResendConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationCodeMutation, { data, loading, error }] = useResendConfirmationCodeMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResendConfirmationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>(ResendConfirmationCodeDocument, options);
      }
export type ResendConfirmationCodeMutationHookResult = ReturnType<typeof useResendConfirmationCodeMutation>;
export type ResendConfirmationCodeMutationResult = Apollo.MutationResult<ResendConfirmationCodeMutation>;
export type ResendConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($user: SignUpInput) {
  signUpUser(signUpInput: $user)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UserByIdDocument = gql`
    query UserById($userId: ID!) {
  userById(userId: $userId) {
    userId
    firstName
    lastName
    email
    emailConfirmed
    isSubscriber
    avatar
    signUpCategory
    lastLoginAt
    currentLoginAt
    acceptMembershipAt
    acceptTrusteeAt
    acceptTermsAt
    acceptPrivacyAt
    userRules
    userRoles {
      roleId
      name
      key
      priority
      purpose
      description
      duties
      timeRequired
      skillsRequired
      isMemberRole
      isCommitteeRole
      isSupporterRole
      isPaidRole
      roleUsers {
        userId
        firstName
        lastName
      }
    }
    userPrivilege {
      privilegeId
      name
      key
    }
    userInstruments {
      instrumentId
      name
    }
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($user: VerifyEmailInput) {
  verifyEmail(verifyEmailInput: $user)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const VerifyUserDocument = gql`
    mutation VerifyUser($user: VerifyInput) {
  verifyUser(verifyInput: $user) {
    userId
    firstName
    lastName
    email
    token
    emailConfirmed
    isSubscriber
    avatar
    signUpCategory
    lastLoginAt
    currentLoginAt
    acceptMembershipAt
    acceptTrusteeAt
    acceptTermsAt
    acceptPrivacyAt
    userRules
    userRoles {
      roleId
      name
      key
      priority
      purpose
      description
      duties
      timeRequired
      skillsRequired
      isMemberRole
      isCommitteeRole
      isSupporterRole
      isPaidRole
      roleUsers {
        userId
        firstName
        lastName
      }
    }
    userPrivilege {
      privilegeId
      name
      key
    }
    userInstruments {
      instrumentId
      name
    }
  }
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, options);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;