import { RoleType } from '../types/User'
import { RoleIdType } from '../generated/graphql'

const getRoleColour = (role: RoleType | undefined): string => {
  if (!role) return 'danger'
  if (role.key === RoleIdType.Member) return 'success'
  if (role.key === RoleIdType.Committee || role.isCommitteeRole)
    return 'primary'
  if (role.key === RoleIdType.Extra) return 'warning'
  if (role.key === RoleIdType.Trustee) return 'danger'

  return 'secondary'
}

export default getRoleColour
