import { UserType, RoleType } from '../types/User'
import { RoleIdType } from '../generated/graphql'
import { getRoleColour } from './index'

const getUserColour = (user: UserType): string => {
  const roles: { [roleId: string]: RoleType } = {}
  const priorities = [RoleIdType.Committee, RoleIdType.Member]

  user.userRoles?.forEach((role) => {
    if (role?.key) roles[role.key] = role
  })

  for (const index in priorities) {
    const priority = priorities[index]

    if (priority in roles) {
      return getRoleColour(roles[priority])
    }
  }

  return 'secondary'
}

export default getUserColour
