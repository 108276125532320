function dateFromTimestampInSeconds(timestamp: number): string {
  const timestampInMilliseconds = new Date(timestamp * 1000)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = timestampInMilliseconds.getFullYear()
  const month = months[timestampInMilliseconds.getMonth()]
  const date = timestampInMilliseconds.getDate()
  const result = `${date} ${month}, ${year}`
  return result
}

export default dateFromTimestampInSeconds
